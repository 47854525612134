import React from 'react';
// import { theme } from './src/ui/theme'; // to override styles create this file
import { MantineProvider, createTheme } from '@mantine/core';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/global.css';
import '@mantine/core/styles.css'; // All packages except `@mantine/hooks` require styles imports

const theme = createTheme({
  fontFamily: 'Konnect, sans-serif',
  fontFamilyMonospace: 'Konnect, monospace',
  headings: { fontFamily: 'Konnect, sans-serif' }
});

export const wrapPageElement = ({ element }) => {
  return <MantineProvider theme={theme}>{element}</MantineProvider>; // theme={theme}
};
